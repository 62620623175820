body, html {
  max-width: 100%;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.banner {
  display: flex;
  align-items: center;
  background-color: #f5e1ccec;
  padding: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.logo {
  width: 245px;
  height: 99px;
}

.links {
  margin-right: auto;
}

.link {
  margin-left: 20px;
  padding: 10px 15px;
  text-decoration: none;
  color: #eb1212;
  border: 1px solid #ff0015;
  border-radius: 5px;
}

.link:hover {
  background-color: #ff0037;
  color: #fff;
}

.table {
  padding: 20px;
}

.analytics {
  padding: 20px;
}

.container {
  display: flex;
  justify-content: flex-end;
  gap: 10px; /* Adjust the space between buttons */
}

.button {
  min-width: '300px'
}

.Button {
  color: #eb1212;
}

.Button:disabled {
  color: white;
}

.uniform-spacing {
  min-height: 60px;
}


.dashboard {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.dashboard-item {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
}

.status-box {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.status-person-list {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.status-person-list li {
  background-color: #e9e9e9;
  margin-top: 5px;
  padding: 5px;
  border-radius: 4px;
}

body {
  background-color: #f5f5f5; /* This is a light grey color */
}

.TopBar {
  padding-right: 0 !important;
}

body .css-5poeop {
  padding-right: 0 !important;
}
